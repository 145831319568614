import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Engagement Cloud Overview`}</h1>
    <p>{`Description of Engagement Cloud concepts and processes.`}</p>
    <p>{`Engagement Cloud is part of CoreMedia Experience Platform. It contains features like lead activation, contact management, segmentation, user creation, analytics, among others.`}</p>
    <p>{`You can integrate Engagement Cloud with Content Cloud.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      